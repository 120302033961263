import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_faq', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_faq', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_faq', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_faq', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_faq', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_faq', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    faqOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/faq_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageFaq(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_faq/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
